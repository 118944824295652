import { Chart } from "chart.js";
let ChartJS: any = Chart;
import ApexCharts from "apexcharts";


export default (data: any, elem: string) => {
  
  var colors = ["#00a6ff", "#33cf00"];
  const fontFamily = "Conv_Montserrat-Regular";
  var ctx: any = document.getElementById(elem);
  ctx = ctx.getContext("2d");
  ctx.canvas.width = 350;
  ctx.canvas.height = 320;
  new ChartJS(ctx, {
    type: "bar",
    data: {
      labels: data.labels,
      datasets: [
        {
          backgroundColor: colors,
          borderColor: "white",
          data: data.series,
          barPercentage: 0.9,
        }
      ]
    },

    options: {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        bodyFontFamily: fontFamily,
        titleFontFamily: fontFamily
      },
      scales: {
        yAxes: [
          {
            ticks: {
              // padding: 10,
              suggestedMin: data.suggestedMin,
              suggestedMax: data.suggestedMax,
              // stepSize: 20,
              beginAtZero: true,
              fontFamily: fontFamily,
              fontColor: "white",
              callback: function (label) {
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            },
            gridLines: { color: "rgb(22, 97, 139)", drawBorder: false }
          }
        ],
        xAxes: [
          {
            ticks: {
              fontColor: "white",
              fontFamily: fontFamily
            },
            gridLines: {
              display: false,
              drawBorder: true,
              color: "white"
            }
          }
        ]
      },
      legend: {
        display: false
      }
    }
  });
};



// export class BarChart {
//   public option = {};
//   public element: string;
//   public chartInstance: ApexCharts;
//   public buildOptions(data: any) {
//     return {
//         series: [{
//         name: 'Net Profit',
//         data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
//       }, {
//         name: 'Revenue',
//         data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
//       }, {
//         name: 'Free Cash Flow',
//         data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
//       }],
//         chart: {
//         type: 'bar',
//         height: 200
//       },
//       plotOptions: {
//         bar: {
//           horizontal: false,
//           columnWidth: '55%',
//           endingShape: 'rounded'
//         },
//       },
//       dataLabels: {
//         enabled: false
//       },
//       stroke: {
//         show: true,
//         width: 2,
//         colors: ['transparent']
//       },
//       xaxis: {
//         categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
//       },
//       yaxis: {
//         title: {
//           text: '$ (thousands)'
//         }
//       },
//       fill: {
//         opacity: 1
//       },
//       tooltip: {
//         y: {
//           formatter: function (val) {
//             return "$ " + val + " thousands"
//           }
//         }
//       }
//     }
//   }

//   constructor(data: any, elem: string) {
//     this.element = elem;
//     this.option = this.buildOptions(data);
//     this.renderDefault();
//   }

//   renderDefault() {
//     this.chartInstance = new ApexCharts(
//       document.querySelector(`#${this.element}`),
//       this.option
//     );
//     this.chartInstance.render();
//   }

//   updateColumnChart(data: any) {
//     this.chartInstance.updateOptions(this.buildOptions(data));
//   }
//   destroyChart() {
//     this.chartInstance.destroy();
//   }
// }