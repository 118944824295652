


import { Component, Vue, Mixins } from "vue-property-decorator";
import { PieChart } from "@/views/charts/pie-chart/pie-chart";
import BarChart from "@/views/charts/bar-chart/bar-chart";
import { BASE_API_URL } from "@/config";
import Axios from "axios";
import CommonMixin from "../../../mixins/common.mixin";
import stores from "@/store/store";
const store: any = stores;

import { LoanReports } from "@/models/common.model";
import '@/vuebar';
let  w = window as any;
const Vuebar = w.Vuebar as any;
Vue.use(Vuebar);
import { HollowDotsSpinner } from "epic-spinners";
import striptags from "striptags";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import DisplayFile from "@/views/DisplayDocument.vue";
import StarRating from "vue-star-rating";
import { loanInfo, borrowerData } from "../../../models/borrowerData.model";
import {
  BorrowerInformation,
  BorrowerDetails
} from "@/models/pos/borrower-info.model";
import { LoanAndProperty } from "@/models/pos/loan-and-property.model";
import {
  DemographicInformation,
  DemographicDetails
} from "@/models/pos/demographic-info.model";
import { Acknowledgement, AckDetail } from "@/models/pos/acknowledgement.model";
import { FinancialInfo } from "@/models/pos/financial-info.model";
import {
  Declaration,
  DeclarationDetails
} from "@/models/pos/declaration.model";
import { TransactionDetail } from "@/models/pos/transaction-detail.model";
import { LogIn } from "../../../models/login.model";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";

@Component({
  components: { HollowDotsSpinner, DisplayFile, StarRating }
})
export default class DashboardComponent extends Mixins(CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public borrowerData = new borrowerData();
  public borrowerInfoData: any = new BorrowerInformation();
  public loanAndProperty = new LoanAndProperty();
  public demographicInfo = new DemographicInformation();
  public acknowledgement = new Acknowledgement();
  public financialInfo = new FinancialInfo();
  public declaration = new Declaration();
  public transactionDetail = new TransactionDetail();
  public customerRetentionLead: any = {};
  public leadsSaveInProcess: boolean = false;
  public inProcessingLoans = [];
  public wemloLeads = [];
  public loanReports = new LoanReports();
  public hasPieData = false;
  public hasBarData = false;
  public pipelineLoader = false;
  public wemloLeadsLoader = false;
  public loanTxnId = null;
  public loanReportLoader = false;
  public loanNotificationLoader = false;
  public loanNotificationLoader1 = false;
  public inProcessingNotification: any = {};
  public wemloLeadNotification: any = {};
  public selectedNotification: any = {};
  public attachment = [];
  public inProcessing = false;
  public isReplied = null;
  public validate = false;
  public rateAndReviews = [];
  public selectedBorrowerName = null;
  public width = 0.8;
  // public name = " ";
  public pieChart: PieChart;
  // public responseFor = null;
  public clearNotification = false;
  public token: any = "";
  public ratingProcessing = false;
  public borrowerName = null;
  public totalDays = null;
  public isMottoUser = false;

  get userType(): string {
    return this.$store.state.sessionObject.type;
  }

  get canStartNewLoan(): boolean {
    if (!this.userType) return false;
    if (this.userType === "Broker") return true;
    if (this.$store.state.sessionObject.access.length === 0) return false;
    return this.$store.state.sessionObject.access[1].canAccess;
  }

  get shareCompanyPipeline() {
    let isBroker = this.$store.state.sessionObject.type == "Broker";
    if (isBroker) return true;
    if (this.$store.state.sessionObject.access.length == 0) return false;
    return this.$store.state.sessionObject.access[2].canAccess;
  }

  get shareBrokerDashboard() {
    let isBroker = this.$store.state.sessionObject.type == "Broker";
    if (isBroker) return true;
    if (this.$store.state.sessionObject.access.length == 0) return false;
    return this.$store.state.sessionObject.access[4].canAccess;
  }

  public async getLoanReports(mountCall) {
    this.loanReportLoader = true;
    try {
      let response = await Axios.get(BASE_API_URL + "broker/getLoanReports", {
        params: {
          userId:
            this.shareBrokerDashboard && this.userType != "Broker"
              ? this.$brokerId
              : this.$userId,
          userType: this.shareBrokerDashboard ? "Broker" : this.userType
        }
      });

      this.loanReports = response.data;
      if (!this.loanReports.pieChartDataset.series.every(v => v == 0)) {
        this.hasPieData = true;
        if (mountCall) {
          this.pieChart = new PieChart(
            this.loanReports.pieChartDataset,
            "pieChart"
          );
        } else {
          this.pieChart.updatePieChart(this.loanReports.pieChartDataset);
        }
      }

      if (this.loanReports.barChartDataset.series.every(v => v == 0)) {
        let dataSet = {
          suggestedMin: 50,
          suggestedMax: 140
        };
        BarChart(dataSet, "barChart");
      } else {
        BarChart(this.loanReports.barChartDataset, "barChart");
      }
      this.loanReportLoader = false;
    } catch (error) {
      this.loanReportLoader = false;
    }
  }

  /**
   * For Loan Closed on the using comma seperator
   */
  public addCommas(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }

  public async getPipeline() {
    this.pipelineLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getPipeline",
        {
          brokerId: this.$brokerId,
          userId: this.$userId,
          type: this.userType,
          sharePipeLineAccess: this.shareCompanyPipeline
        });
      this.inProcessingLoans = response.data.data;
      this.pipelineLoader = false;
    } catch (error) {
      this.pipelineLoader = false;
    }
  }

  public getTrimmedMessageBody(message) {
    let msg = striptags(message, [], " "); //To strip html into normal text.
    msg = msg.replace(/\s\s+/g, " "); //To remove multiple white spaces as above function create multiple white spaces in case of <ol> and <ul> tags.
    msg = msg.replace(/&amp;/g, "&"); //To handle ampersand case
    if (msg.length > 25) {
      return msg.slice(0, 24) + "...";
    } else {
      return msg;
    }
  }

  get shareWemloLeads() {
    let isBroker = this.$store.state.sessionObject.type == "Broker";
    if (isBroker) return true;
    if (this.$store.state.sessionObject.access.length == 0) return false;
    return this.$store.state.sessionObject.access[3].canAccess;
  }

  public async getWemloLead() {
    this.wemloLeadsLoader = true;
    try {
      let res = await Axios.post(
        BASE_API_URL + "broker/getWemloLead",
        {
          brokerId: this.$brokerId,
          userId: this.$userId,
          type: this.userType,
          sharePipeLineAccess: this.shareWemloLeads
        });
      this.wemloLeads = res.data.data;

      this.wemloLeadsLoader = false;
    } catch (error) {
      this.wemloLeadsLoader = false;
    }
  }

  public async toggleNotification(i, selectedBorrower, inProcessing) {
    try {
      if (inProcessing) {
        this.loanNotificationLoader = true;
        this.inProcessingNotification["borrower" + i] = !this
          .inProcessingNotification["borrower" + i];
        this.inProcessingNotification = JSON.parse(
          JSON.stringify(this.inProcessingNotification)
        );
      } else {
        this.loanNotificationLoader1 = true;
        this.wemloLeadNotification["wemloLeadList" + i] = !this
          .wemloLeadNotification["wemloLeadList" + i];

        this.wemloLeadNotification = JSON.parse(
          JSON.stringify(this.wemloLeadNotification)
        );
      }
      let response = await Axios.get(
        BASE_API_URL + "common/getLoanUnreadNotifications/",
        {
          params: {
            id: selectedBorrower.loanTxnId,
            userId: this.$userId,
            processorType: null
          }
        }
      );
      if (inProcessing) {
        this.inProcessingLoans.forEach(element => {
          if (element._id == selectedBorrower._id) {
            element.communication = response.data;
            element.notificationCount = response.data.length;
          } else {
            element.communication = [];
          }
        });
        this.loanNotificationLoader = false;
        this.inProcessingLoans = JSON.parse(
          JSON.stringify(this.inProcessingLoans)
        );
      } else {
        this.wemloLeads.forEach(element => {
          if (element._id == selectedBorrower._id) {
            element.communication = response.data;
            element.notificationCount = response.data.length;
          } else {
            element.communication = [];
          }
        });
        this.loanNotificationLoader1 = false;
      }
    } catch (error) {
      console.log(error.response);
    }
  }
  public async markNotificationAsRead(notificationId, loanTxnId, inProcessing) {
    try {
      if (inProcessing) {
        this.inProcessingLoans.forEach(element => {
          if (element.loanTxnId == loanTxnId) {
            element.notificationCount--;
            element.communication = element.communication.filter(
              e => e._id != notificationId
            );
          }
        });
        this.inProcessingLoans = JSON.parse(
          JSON.stringify(this.inProcessingLoans)
        );
      } else {
        this.wemloLeads.forEach(element => {
          if (element.loanTxnId == loanTxnId) {
            element.notificationCount--;
            element.communication = element.communication.filter(
              e => e._id != notificationId
            );
          }
        });

        this.wemloLeads = JSON.parse(JSON.stringify(this.wemloLeads));
      }
      let readNotificationId = [notificationId];
      let response = await Axios.post(
        BASE_API_URL + "communication/changeNotificationisReadStatus",
        {
          unReadedCommunications: readNotificationId
        });
    } catch (error) {
      console.log(error.data);
    }
  }

  //Clearing Document Notification Count when click on Wemloleads page
  public async clearDocumentCount(details) {
    try {
      let wemloLeadInfo = details;
      let response = await Axios.get(
        BASE_API_URL + "common/getLoanUnreadNotifications/",
        {
          params: {
            id: details.loanTxnId,
            userId: this.$userId,
            processorType: null
          }
        }
      );

      wemloLeadInfo.communication = response.data;
      wemloLeadInfo.notificationCount = response.data.length;
      let notificationId = [];
      wemloLeadInfo.communication.forEach(e => {
        if (e.moduleType != "wemlo message") {
          notificationId.push(e._id);
        }
      });
      if (notificationId.length > 0) {
        let response1 = await Axios.post(
          BASE_API_URL + "communication/changeNotificationisReadStatus",
          {
            unReadedCommunications: notificationId
          });
      }
    } catch (error) {
      console.log(error.data);
    }
  }

  public openBorrowerInfoModel(leadInfo) {
    try {
      this.customerRetentionLead = leadInfo;
      this.borrowerInfoData.borrowerInfo[0].personalInfo.name.firstName =
        leadInfo.basicInformation.borrower.firstName;
      this.borrowerInfoData.borrowerInfo[0].personalInfo.name.lastName =
        leadInfo.basicInformation.borrower.lastName;
      this.borrowerInfoData.borrowerInfo[0].personalInfo.contactInformation.email =
        leadInfo.basicInformation.borrower.email;
      this.borrowerInfoData.borrowerInfo[0].personalInfo.contactInformation.cellPhone =
        leadInfo.basicInformation.borrower.phone;
      this.$modal.show("borrowerInfoModel");
    } catch (error) {
      console.log(error);
    }
  }

  public closeBorrowerInfoModel() {
    try {
      this.borrowerData = new borrowerData();
      this.borrowerInfoData = new BorrowerInformation();
      this.loanAndProperty = new LoanAndProperty();
      this.demographicInfo = new DemographicInformation();
      this.acknowledgement = new Acknowledgement();
      this.financialInfo = new FinancialInfo();
      this.declaration = new Declaration();
      this.transactionDetail = new TransactionDetail();
      this.customerRetentionLead = {};
      this.$modal.hide("borrowerInfoModel");
    } catch (error) {
      console.log(error);
    }
  }

  public async saveCustomerRententionLeadAsBorrower() {
    try {
      let formValid = await this.$validator.validateAll("borrowerInfo");
      let haveDuplicate = this.borrowerInfoData.borrowerInfo.find(
        a => a.duplicateEmail
        //  || a.duplicatePhone
      );
      if (!formValid || haveDuplicate) {
        return;
      }
      this.leadsSaveInProcess = true;
      let body: any = {
        borrowerInfo: this.borrowerInfoData,
        loanAndProperty: this.loanAndProperty,
        demographicInfo: this.demographicInfo,
        acknowledgement: this.acknowledgement,
        financialInfo: this.financialInfo,
        declaration: this.declaration,
        transactionDetail: this.transactionDetail,
        borrowerData: this.borrowerData,
        customerRetentionLead: this.customerRetentionLead
      };
      body.borrowerData.source = "stikkum";
      if (!body.borrowerData.loanTxnId) {
        body.borrowerData.loanTxnId = this.$random(8);
      }
      let loanTxnId = body.borrowerData.loanTxnId;
      body.borrowerData.addedBy = this.$userId;
      body.borrowerData.originatorModelName =
        this.userType == "Admin Staff" ? "Admin-Staff" : this.userType;
      body.borrowerData.brokerId = this.$brokerId;
      body.loanAndProperty.loanTxnId = body.borrowerInfo.loanTxnId = body.demographicInfo.loanTxnId = body.acknowledgement.loanTxnId = body.financialInfo.loanTxnId = body.declaration.loanTxnId = body.transactionDetail.loanTxnId =
        body.borrowerData.loanTxnId;
      //Sending data to the Server In Borrowers Collection
      let response = await Axios.post(
        BASE_API_URL + "loan/submitBorrowerInfo",
        body);

      if (response.data.type == "error") {
        this.borrowerInfoData.borrowerInfo = response.data.borrowerInfo;
      } else if (response.data.type == "success") {
        this.closeBorrowerInfoModel();
        this.$router.push({
          path: "broker-loan-documents",
          query: { id: loanTxnId, edit: "true" }
        });
      }
      this.leadsSaveInProcess = false;
    } catch (error) {
      console.log(error, "error");
      this.leadsSaveInProcess = false;
    }
  }

  public checkDuplicate() {
    try {
      this.borrowerInfoData.borrowerInfo.forEach(el => {
        // el.duplicatePhone = false;
        el.duplicateEmail = false;
      });
      this.borrowerInfoData.borrowerInfo.forEach((element, index) => {
        this.borrowerInfoData.borrowerInfo.forEach((el, i) => {
          if (index < i) {
            if (
              element.personalInfo.contactInformation.email &&
              element.personalInfo.contactInformation.email ==
                el.personalInfo.contactInformation.email
            )
              el.duplicateEmail = true;
            // if (
            //   element.personalInfo.contactInformation.cellPhone &&
            //   element.personalInfo.contactInformation.cellPhone ==
            //     el.personalInfo.contactInformation.cellPhone
            // )
            //   el.duplicatePhone = true;
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

  public addBorrower() {
    let borrower: any = new BorrowerInformation();
    this.borrowerInfoData.borrowerInfo.push({
      ...borrower.borrowerInfo[0],
      duplicateEmail: false
      // duplicatePhone: false
    });
  }

  public removeBorrower(index) {
    let borrowerIndex = this.borrowerInfoData.borrowerInfo[index].personalInfo
      .jointWithCoBorrowerIndex;
    if (borrowerIndex != null) {
      this.borrowerInfoData.borrowerInfo.forEach((el, i) => {
        if (el.personalInfo.jointWithCoBorrowerIndex == index) {
          el.personalInfo.jointWithCoBorrowerIndex = null;
          el.personalInfo.relationWithBorrower = null;
        }
      });
    }
    this.borrowerInfoData.borrowerInfo.splice(index, 1);
  }

  getBorrowerName(index) {
    let borrower = this.borrowerInfoData.borrowerInfo[index].personalInfo;
    let name = null;
    name =
      borrower.name.firstName +
      " " +
      (borrower.name.lastName ? borrower.name.lastName : "");
    return name;
  }

  /**
   *  Check Borrower have Co-Borrower or Not
   */
  haveAdditionalBorrower(index) {
    let borrower = this.borrowerInfoData.borrowerInfo[index].personalInfo;
    if (
      borrower.name.firstName &&
      borrower.relationWithBorrower != "Spouse" &&
      !borrower.jointWithCoBorrowerIndex
    ) {
      return true;
    } else {
      return false;
    }
  }
  /**
   *  remove jointWIthCoBorrowerIndex if relation with borrower is not spouse
   */
  changeBorrowerRelation(index) {
    if (
      this.borrowerInfoData.borrowerInfo[index].personalInfo
        .relationWithBorrower != "Spouse"
    ) {
      let borrowerIndex = this.borrowerInfoData.borrowerInfo[index].personalInfo
        .jointWithCoBorrowerIndex;
      if (borrowerIndex != null) {
        this.borrowerInfoData.borrowerInfo[
          borrowerIndex
        ].personalInfo.jointWithCoBorrowerIndex = null;
      }
      this.borrowerInfoData.borrowerInfo[
        index
      ].personalInfo.jointWithCoBorrowerIndex = null;
    }
    this.borrowerInfoData = JSON.parse(JSON.stringify(this.borrowerInfoData));
  }
  /**
   *  add coBorrower index in borrower information and remove from otherBorrower's information if have that same index
   */
  changeBorrowerRelationIndex(index) {
    this.borrowerInfoData.borrowerInfo.forEach(el => {
      if (
        index == el.personalInfo.jointWithCoBorrowerIndex &&
        el.personalInfo.relationWithBorrower != "Spouse"
      ) {
        el.personalInfo.jointWithCoBorrowerIndex = null;
      }
    });
    let borrowerIndex = this.borrowerInfoData.borrowerInfo[index].personalInfo
      .jointWithCoBorrowerIndex;
    this.borrowerInfoData.borrowerInfo.forEach((element, i) => {
      if (
        i != borrowerIndex &&
        element.personalInfo.relationWithBorrower != "Spouse" &&
        element.personalInfo.jointWithCoBorrowerIndex == borrowerIndex
      ) {
        element.personalInfo.jointWithCoBorrowerIndex = null;
      } else if (
        i == borrowerIndex &&
        element.personalInfo.relationWithBorrower != "Spouse"
      ) {
        element.personalInfo.jointWithCoBorrowerIndex = index;
      }
    });
    this.borrowerInfoData = JSON.parse(JSON.stringify(this.borrowerInfoData));
  }

  public async viewCommDetail(
    commId,
    borrowerId,
    notification,
    source,
    inProcessing,
    index
  ) {
    this.selectedBorrowerName = inProcessing
      ? this.inProcessingLoans[index].borrowerName
      : this.wemloLeads[index].borrowerName;
    this.clearNotification = inProcessing;
    let selectedBorrower = inProcessing
      ? this.inProcessingLoans.find(a => a._id == borrowerId)
      : this.wemloLeads.find(a => a._id == borrowerId);
    if (selectedBorrower) {
      let selectedComm = selectedBorrower.communication.find(
        a => a._id == commId
      );
      if (selectedComm) {
        let response = await Axios.post(
          BASE_API_URL + "broker/getDocumentDetails/",
          {
            loanTxnId: selectedComm.loanTxnId,
            docId: selectedComm.moduleId
          });
        this.selectedNotification = {
          fromType: selectedComm.fromType,
          fromUserId: selectedComm.fromUserId,
          loanTxnId: selectedComm.loanTxnId,
          id: selectedComm._id,
          responseFor: notification.moduleId
        };
        // this.responseFor = notification.moduleId;
        if (selectedComm.moduleType == "wemlo message") {
          if (response.data.communications) {
            let selectedModuleId = response.data.communications.find(
              a => a._id == selectedComm.moduleId
            );
            if (selectedModuleId) {
              this.selectedNotification["body"] = selectedModuleId.body;
              this.attachment = selectedModuleId.attachments;
              this.$modal.show("displayWemloMessage");
            }
          }
        } else if (selectedComm.moduleType == "comment") {
          if (response.data.comment) {
            let selectedModuleId = response.data.comment.find(
              a => a._id == selectedComm.moduleId
            );
            if (selectedModuleId) {
              let comment = notification.description;
              let attachments = selectedModuleId.attachments;
              if (attachments.length > 0) {
                this.$refs.df["setInitialValuesForAttachmentsAndComments"](
                  comment,
                  attachments
                );
              } else {
                this.$refs.df["setInitialValuesForShowMessage"](comment);
              }
            }
          }
        } else if (selectedComm.moduleType == "Loan Estimate") {
          let allDocs = response.data.docs;
          let comments = response.data.comment;
          if (!comments) comments = [];
          let reviewDoc = response.data.reviewDoc;
          this.$refs.df["setInitialValues"](
            allDocs,
            comments,
            reviewDoc,
            true,
            selectedComm.loanTxnId,
            true,
            true,
            source
          );
        } else if (selectedComm.moduleType == "Loan Task") {
          this.$refs.df["setInitialValuesForShowMessage"](
            notification.description
          );
        } else if (selectedComm.moduleInfo == "Pending documents") {
          let selectedModuleId = response.data.docs.find(
            a => a._id == selectedComm.moduleId
          );
          if (selectedModuleId) {
            let comment = null;
            let attachments =
              selectedModuleId.fileHistory[
                selectedModuleId.fileHistory.length - 1
              ].files;
            if (attachments.length > 0) {
              this.$refs.df["setInitialValuesForAttachmentsAndComments"](
                comment,
                attachments
              );
            }
          }
        }
      }
    }
  }

  public displayDocuments(files) {
    this.$refs.df["setInitialValuesForAttachmentsAndComments"](null, files);
  }

  public displayCustomTaskFile(files) {
    this.$refs.df["setInitialValuesForLocalFiles"](files, true, "file");
  }

  private deleteDoc (data){
    if (this[data.deleteData].length == 1) {
      let messageFiles: any = this.$refs.messageFiles;
      messageFiles.value = null;
    }
    this[data.deleteData].splice(data.index, 1);
  }


  public async addMessage() {
    let valid = await this.$validator.validate("message", this.message);
    let res = await Axios.post(
      BASE_API_URL + "broker/checkUserExist",
      {
        checkId: this.selectedNotification.fromUserId,
        userId: this.$userId
      });
    if (res.data.message == "User not exists") {
      this.$snotify.error(res.data.message);
      return;
    }

    this.inProcessing = true;
    if (valid) {
      let msg: any = await this.sendMessage({
        toUserType: this.selectedNotification.fromType,
        toUserId: this.selectedNotification.fromUserId,
        loanTxnId: this.selectedNotification.loanTxnId,
        isCalledFromDashboard: true,
        notificatioModuleId: this.selectedNotification.responseFor,
        // communicationId: this.communicationId,
        // responseFor: this.responseFor,
        borrowerName: this.selectedBorrowerName
      });
      this.$snotify.success("Message sent successfully.");
      this.isReplied = true;
      this.inProcessing = false;
    } else {
      this.inProcessing = false;
      this.showMessageError = true;
      setTimeout(() => {
        this.showMessageError = false;
      }, 1500);
    }
  }

  public async closeCommDetail() {
    this.file = [];
    let releaseNotification = false;
    if (this.isReplied) {
      await this.$dialog
        .confirm("Do you want to clear this notification?")
        .then(e => {
          releaseNotification = true;
        })
        .catch(f => {
          releaseNotification = false;
        });
    }

    if (releaseNotification) {
      this.markNotificationAsRead(
        this.selectedNotification.id,
        this.selectedNotification.loanTxnId,
        this.clearNotification
      );
    }
    this.$modal.hide("displayWemloMessage");
    this.selectedNotification = {};
    this.message = null;
    this.attachment = [];
    this.isReplied = false;
    this.clearNotification = false;
  }

  public fileUpload(event) {
    try {
      if (event.target.files.length == 0) return;
      this.file = [];
      Object.values(event.target.files).forEach(event => {
        this.file.push(event);
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async skipRating(loanTxnId) {
    try {
      /**Check this loanTxnId have  already ratings or not */
      let toCheck = await Axios.post(
        BASE_API_URL + "broker/toCheckAlreadyHaveRatings",
        { loanTxnId: loanTxnId });
      let sure = false;
      if (toCheck.data.alreadyHaveRatings) {
        await this.$dialog
          .confirm(
            "This LoanTxnId have already Ratings.\n Do you want to clear all that\n"
          )
          .then(e => {
            sure = true;
            this.$snotify.info("Skipping and Clearing the Ratings");
          })
          .catch(f => {
            sure = false;
            this.$snotify.info(
              "Skipping without clearing the existing Ratings"
            );
          });
      }
      /**************************************************** */
      let response = await Axios.post(
        BASE_API_URL + "broker/skipRating",
        { loanTxnId: loanTxnId, sure: sure });
      this.getPipeline();
    } catch (error) {
      console.log(error);
    }
  }

  public getBorrowers(mountCall) {
    this.getLoanReports(mountCall);
    this.getPipeline();
    this.getWemloLead();
  }
  // star rating and reviews
  public async showStars(data) {
    try {
      this.loanTxnId = data.loanTxnId;
      let response = await Axios.post(
        BASE_API_URL + "broker/reviewsforprocessors",
        { loanTxnId: data.loanTxnId });
      this.rateAndReviews = response.data.wemloStaffData;
      this.borrowerName = data.borrowerName;
      this.totalDays = response.data.totalDay;
    } catch (error) {
      console.log(error);
    }
  }
  public async submitReviewsAndComments() {
    try {
      this.ratingProcessing = true;
      let notValid = this.rateAndReviews.find(e => e.rating < 3 && !e.feedBack);
      if (notValid) {
        this.ratingProcessing = false;
        return;
      }
      let rateObj = [];
      let rateAndReviews = this.rateAndReviews.filter(s =>
        s.hasOwnProperty("rating")
      );
      if (rateAndReviews.length == 0) {
        this.ratingProcessing = false;
        this.validate = true;
        return;
      }
      let response = await Axios.post(
        BASE_API_URL + "broker/submitReviewsAndComments/",
        {
          rateAndReviews: rateAndReviews,
          loanTxnId: this.loanTxnId,
          brokerId: this.$brokerId,
          ratedByType: this.$userType
        });
      this.$snotify.success(response.data.message);
      this.ratingProcessing = false;
      this.getPipeline();
      this.$modal.hide("ratingModal");
      // this.getBorrowers(true);
    } catch (error) {
      console.log(error);
      this.ratingProcessing = false;
    }
  }
  public hideratingModal() {
    this.$modal.hide("ratingModal");
    this.ratingProcessing = false;
  }

  public async loadImage (file, index) {
    try {
      let ext = file.split(".").pop().toLowerCase();
      let response = await Axios.post(BASE_API_URL + 'common/fetchDocument',
        { path: file });
      let img =  `data:image/${ext};base64,` + response.data;
      let dom = document.getElementById('imageElement' + index);
      let imgTag = document.createElement('img');
      imgTag.src = img;
      dom.innerHTML = "";
      dom.appendChild(imgTag);
    } catch (error) {
      console.log(error);
    }
  }

  mounted() {
    this.isMottoUser = JSON.parse(localStorage.getItem('state.WeMLO')).sessionObject?.userInfo?.isMottoUser;
    this.getBorrowers(true);

    if (this.$loader) {
      this.$loader.hide();
    }
  }
}
